import { FC } from 'react';
import { Spin } from 'antd';
import './Spinner.less';

interface Props {
	size?: 'default' | 'small' | 'large';
}

const Spinner: FC<Props> = ({ size = 'large' }) => {
	return (
		<div className="spinner">
			<Spin size={size} />
		</div>
	);
};

export default Spinner;
